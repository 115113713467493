import Helper from '@ember/component/helper';
import { inject as service } from '@ember/service';
import type AnalyticsService from 'dashboard/services/analytics';
import type { AnalyticsEventName, AnalyticsMetadata } from 'dashboard/types';

type Positional = [AnalyticsEventName, AnalyticsMetadata?];

export interface TrackEventSignature {
  Args: {
    Positional: Positional;
  };
  Return: () => void;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'track-event': typeof TrackEvent;
  }
}

export default class TrackEvent extends Helper<TrackEventSignature> {
  @service declare analytics: AnalyticsService;

  public compute(params: Positional): () => void {
    return () => {
      this.analytics.track(params[0], params[1]);
    };
  }
}
