import Service from '@ember/service';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import ENV from 'dashboard/config/environment';
import Analytics from 'analytics';
import amplitudePlugin from '@analytics/amplitude';
import type { AnalyticsInstance } from 'analytics';
import type {
  AnalyticsAppArea,
  AnalyticsEventName,
  AnalyticsMetadata,
  Transition,
} from 'dashboard/types';
import RouterService from '@ember/routing/router-service';

interface IAnalyticsAreaRouteMap {
  [key: string]: AnalyticsAppArea;
}
type AnalyticsAreaRouteKey = keyof typeof AnalyticsAreaRouteMap;

/** @see router.js */
const AnalyticsAreaRouteMap: IAnalyticsAreaRouteMap = {
  'dashboard.account.index': 'home',
  'dashboard.account.settings.index': 'settings_admins',
  'dashboard.account.settings.account-review': 'wizard_new_user_review',
  'dashboard.account.students': 'students',
  'dashboard.account.teacher.courses': 'teachers_classes',
  'dashboard.account.teacher.index': 'teachers_account',
  'dashboard.account.teachers': 'teachers',
} as const;

interface AnalyticsPlugin extends AnalyticsInstance {
  // See for more: https://getanalytics.io/api/
  track(
    eventName: AnalyticsEventName,
    payload?: AnalyticsMetadata
  ): Promise<any>;
}

export default class AnalyticsService extends Service {
  @service declare router: RouterService;

  inArea: AnalyticsAppArea | null = null;

  constructor(...args: []) {
    super(...args);
    // listen for route changes and set inArea accordingly
    this.router.on('routeDidChange', (transition: Transition) => {
      const name = transition.to?.name ?? '';
      if (name in AnalyticsAreaRouteMap) {
        this.inArea = AnalyticsAreaRouteMap[name as AnalyticsAreaRouteKey];
      } else {
        this.inArea = null;
      }
    });
  }

  @tracked analytics: AnalyticsPlugin = Analytics({
    app: 'admin-dashboard',
    debug: !ENV.APP.analytics.enabled,
    plugins: ENV.APP.analytics.enabled
      ? [
          // https://bit.ly/3dRdZnE
          amplitudePlugin({
            apiKey: ENV.APP.analytics.amplitudeApiKey,
            options: {
              trackingOptions: {
                ip_address: false,
              },
            },
          }),
        ]
      : [],
  });

  track(eventName: AnalyticsEventName, payload?: AnalyticsMetadata) {
    if (this.inArea) {
      payload = payload || {};
      payload = {
        inArea: this.inArea,
        ...payload,
      };
    }
    return this.analytics.track(eventName, payload);
  }
}
