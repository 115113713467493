import Oauth2Authenticator from './oauth2';
import { inject as service } from '@ember/service';
import SessionService from 'dashboard/services/session';
import { encode } from 'js-base64';
import { type ShowbieSessionData, SSOProvider } from 'dashboard/types';

export default class FeideAuthenticator extends Oauth2Authenticator {
  @service declare session: SessionService;

  /**
   * Perform an OpenID Connect RP-Initiated Logout.
   *
   * This flow is facilitated by the Showbie Core service because the OIDC Feide
   * requires the `id_token` to be passed to their "end session" endpoint. This
   * token is received when the user signs-in and cannot be safely stored on
   * the client; it is passed to the service along with the `access_token` at
   * authorization time.
   *
   * Note that this method returns a promise that ***always resolves***.
   *
   * Also note that, following Feide's recommendation, we clear the local
   * session immediately here (by way of resolving this `invalidate` method)
   * and then triggering the OIDC Logout flow via the ESA `handleInvalidation`
   * method in the session service. That method looks for the `oidcLogoutUrl`
   * flag that we set here to trigger the redirect flow. This configuration
   * effectively implements the flow that Feide recommends.
   *
   * > In the application, include a logout link that performs the following:
   * >   First, kills the local session for the current user.
   * >   Then, redirects the user to the end session endpoint.
   * >
   * > -- source: https://docs.feide.no/service_providers/openid_connect/oidc_authentication.html#logout
   *
   * @see https://docs.feide.no/service_providers/openid_connect/oidc_authentication.html#logout
   * @see https://docs.feide.no/service_providers/manage/openid_connect/redir_etter_logout.html
   * @see https://openid.net/specs/openid-connect-rpinitiated-1_0.html#RPLogout
   * @see https://auth.dataporten.no/.well-known/openid-configuration
   */
  async invalidate(data: ShowbieSessionData): Promise<unknown> {
    const url = this.buildShowbieLogoutUrl(data);
    this.session.oidcLogoutUrl = url;

    return Promise.resolve();
  }

  /**
   * Build the Single Logout URL.
   * @see https://github.com/showbie/showbieservice/wiki/Sessions#oidc-logout
   */
  private buildShowbieLogoutUrl({
    token,
    fingerprint,
  }: ShowbieSessionData): string {
    const { origin } = window.location;
    const base = this.session.buildUrl('sessions/oidc/logout');
    const params = {
      token: encode(token),
      fp: encode(fingerprint),
      provider: SSOProvider.FEIDE,
      /**
       * /oauth/feide/logout-redirect does not exist in our client application;
       * it simply triggers the wildcard route and gets redirected to index (/)
       * route and the app boots as it would normally.
       * We are using this route instead of the naked origin so that we can
       * easily detect Feide SLO redirects in the future if needed, without
       * updating the Core service registered whitelist.
       */
      redirect_uri: `${origin}/oauth/feide/logout-redirect`,
    };

    // NOTE: encodeURIComponent is not needed because URLSearchParams.toString() does it automatically.
    const qs = new URLSearchParams(params);

    return `${base}?${qs.toString()}`;
  }
}
