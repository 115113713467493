import Service from '@ember/service';
import { SlAlert } from '@shoelace-style/shoelace';
import ENV from 'dashboard/config/environment';
import { type SlVariant } from 'dashboard/types';

const { notifyHideDelay } = ENV.APP;

// Always escape HTML for text arguments!
function escapeHtml(html: string) {
  const div = document.createElement('div');
  div.textContent = html;
  return div.innerHTML;
}

export default class ToastService extends Service {
  /**
   * Dynamically create a toast notification using the Shoelace SlAlert stack.
   * @see https://shoelace.style/components/alert/#creating-toasts-imperatively
   */
  notify(
    message: string,
    variant: SlVariant = 'primary',
    duration = notifyHideDelay || 5000
  ): SlAlert {
    let icon;
    // switch (variant) {
    //   case 'danger':
    //     icon = 'no-entry';
    //     break;
    // }

    // <sl-alert variant="neutral" open closable>
    //   <sl-icon slot="icon" name="gear" class="text-white"></sl-icon>
    //   <strong>Your settings have been updated</strong><br />
    //   Settings will take effect on next login.
    // </sl-alert>

    const iconHTML = icon
      ? `<sl-icon library="showbie" name="${icon}" slot="icon" class="w-12 h-12"></sl-icon>`
      : '';

    const alert = Object.assign(document.createElement('sl-alert'), {
      variant,
      closable: true,
      duration,
      innerHTML: `
        ${iconHTML}
        ${escapeHtml(message)}
      `,
    });
    alert.classList.add('lbx-toast');

    document.body.append(alert);
    alert.toast();
    return alert;
  }
}

// Don't remove this declaration: this is what enables TypeScript to resolve
// this service using `Owner.lookup('service:toast')`, as well
// as to check when you pass the service name as an argument to the decorator,
// like `@service('toast') declare altName: ToastService;`.
declare module '@ember/service' {
  interface Registry {
    toast: ToastService;
  }
}
