import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import type RouterService from '@ember/routing/router-service';
import CustomSessionService from 'dashboard/services/session';
import type { Transition } from 'dashboard/types';
import CurrentUserService from 'dashboard/services/current-user';

export default class StudentsRoute extends Route {
  @service declare currentUser: CurrentUserService;
  @service declare router: RouterService;
  @service declare session: CustomSessionService;

  async beforeModel(transition: Transition): Promise<void> {
    this.session.requireAuthentication(transition, 'login');
    await this.currentUser.load();

    const { selectedPurchaserId } = this.currentUser;
    this.router.replaceWith('dashboard.account.students', selectedPurchaserId);
  }
}
