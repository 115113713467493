import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import type RouterService from '@ember/routing/router-service';
// import Transition from '@ember/routing/-private/transition';
// import CurrentUserService from 'dashboard/services/current-user';
// import CustomSessionService from 'dashboard/services/session';
// import { Transition } from 'dashboard/types';

// const SHORTCUT_PATHS = ['teachers', 'analytics'];
export default class NotFoundRoute extends Route {
  // @service declare currentUser: CurrentUserService;
  @service declare router: RouterService;
  // @service declare session: CustomSessionService;

  // async beforeModel(transition: Transition): Promise<void> {
  //   if (transition?.to?.paramNames?.[0] === 'path') {
  //     if (SHORTCUT_PATHS.includes(transition.to?.params['path'] ?? '')) {
  //       // debugger;
  //       console.log('transition', transition);
  //       this.session.requireAuthentication(transition, 'login');
  //       await this.currentUser.load();
  //       const { selectedPurchaserId } = this.currentUser;
  //       this.router.replaceWith(
  //         'dashboard.account.teachers',
  //         selectedPurchaserId
  //       );
  //     }
  //   }
  // }

  redirect(): void {
    this.router.replaceWith('dashboard');
  }
}
