import type { School } from 'dashboard/types';

/**
 * This takes a list of Schools and does two things:
 *   - groups entities by type, with Districts at the top
 *   - sorts each group alphabetically by name
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort#description
 */
export default function sortPurchasers(purchasers: School[]): School[] {
  const naturalSort = (a: School, b: School): number =>
    a.name.localeCompare(b.name);

  const districts = purchasers.filter((x) => x.type === 'District');
  // account for School as well as Business accounts
  const schools = purchasers.filter((x) => x.type !== 'District');

  return [...districts.sort(naturalSort), ...schools.sort(naturalSort)];
}
