import ApolloService from 'ember-apollo-client/services/apollo';
import { inject as service } from '@ember/service';
import {
  ApolloLink,
  InMemoryCache,
  createHttpLink,
  from,
  type ServerError,
} from '@apollo/client/core';
import { relayStylePagination } from '@apollo/client/utilities';
import { onError } from '@apollo/client/link/error';
import type HttpAnalyticsService from 'dashboard/services/http-analytics';
import type RouterService from '@ember/routing/router-service';
import type SessionService from 'dashboard/services/session';
import { encode } from 'js-base64';
import fetch from 'fetch';
import ENV from 'dashboard/config/environment';
import { isPaywallErrorCode } from 'dashboard/utils/errors';

export default class CustomApolloService extends ApolloService {
  @service declare session: SessionService;
  @service declare httpAnalytics: HttpAnalyticsService;
  @service declare router: RouterService;

  apiNamespace = ENV.apiConfig.services.admin.namespace;

  cache(): InMemoryCache {
    // @see https://www.apollographql.com/docs/react/caching/cache-configuration
    const cache = new InMemoryCache({
      // @see https://www.apollographql.com/docs/react/caching/cache-field-behavior
      typePolicies: {
        Query: {
          fields: {
            users: {
              ...relayStylePagination(),
              merge(_existing, incoming) {
                // TODO: Avoiding a multi-page cache for now, perhaps revisit later.
                return incoming;
              },
            },
          },
        },
      },
    });

    return cache;
  }

  link(): ApolloLink {
    // see https://www.apollographql.com/docs/react/api/link/apollo-link-http/
    // see https://www.apollographql.com/docs/react/networking/advanced-http-networking/

    // dynamic URI configuration:
    // https://www.apollographql.com/docs/react/networking/advanced-http-networking/#custom-fetching
    const customFetch = (uri: string, options: RequestInit) => {
      const fullUri = this.session.buildUrl(uri, this.apiNamespace);
      return fetch(fullUri, options);
    };

    const httpLink: ApolloLink = createHttpLink({
      fetch: customFetch,
    });

    if (!this.session.isAuthenticated) {
      return httpLink;
    }

    const authLink = new ApolloLink((operation, forward) => {
      let token: string;

      if (this.session.isBlingMode) {
        token = `Bearer ${this.session.data?.authenticated.token as string}`;
      } else if (this.session.isAuthenticated) {
        token = `sbe token=${encode(
          this.session.data?.authenticated.token as string
        )},fp=${encode(
          this.session.data?.authenticated.fingerprint as string
        )}`;
      }

      operation.setContext(() => {
        return {
          headers: {
            Authorization: token,
            ...this.httpAnalytics.headers,
          },
        };
      });
      return forward(operation);
    });

    const errorLink = onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors && graphQLErrors.length > 0) {
        graphQLErrors.forEach((error) => {
          if (error) {
            const { extensions } = error;
            if (!extensions) {
              console.debug(`[GraphQL error]`);
              console.log(error);
              return;
            }
            console.debug(
              `[GraphQL error]: Status: ${extensions.status}, Code: ${extensions.code}, Message: ${error.message}`
            );
            // Handling select errors
            if (isPaywallErrorCode(error.extensions?.code)) {
              this.router.transitionTo('dashboard.index', {
                queryParams: {
                  paywall: true,
                },
              });
            }
          }
        });
      }

      if (networkError) {
        console.error(`[Network error]: ${networkError}`);
        if (
          networkError &&
          (networkError as ServerError) &&
          (networkError as ServerError).statusCode === 401
        ) {
          this.session.invalidate();
        }
      }
    });

    return from([errorLink, authLink, httpLink]);
  }
}
