// @see https://developers.google.com/identity/gsi/web/guides/use-one-tap-js-api
import {
  type ShowbieOIDCRequestBody,
  type ShowbieSessionData,
  SSOProvider,
} from 'dashboard/types';
import OAuthAuthenticator from './oauth2';
import ENV from 'dashboard/config/environment';
import fetch from 'fetch';
import { v4 as uuidv4 } from 'uuid';
import { resolveRegion } from '@showbie/authentication';
import { ShowbieServiceError } from 'dashboard/utils/errors';
import type { RegionResolutionData } from '@showbie/authentication';
import type { ShowbieFetchHeaders } from 'dashboard/services/http-analytics';

const { auth } = ENV.apiConfig.services;

export default class GoogleAuthenticator extends OAuthAuthenticator {
  // @ts-expect-error need to update the `authenticate` param typing to support OIDC
  async authenticate({
    credential,
  }: google.accounts.id.CredentialResponse): Promise<ShowbieSessionData> {
    const fingerprint = uuidv4();
    const headers = this.httpAnalytics.headers;
    const provider = SSOProvider.GOOGLE;

    const regionData: RegionResolutionData = {
      contextType: 'sso-oidc',
      context: credential,
      provider,
    };
    const opts = { headers: { ...headers } as ShowbieFetchHeaders };

    // resolve and store the regional origin
    const region = await resolveRegion(regionData, opts, auth.origin);
    this.session.setApiRegion(region);

    const requestBody: ShowbieOIDCRequestBody = {
      grant_type: 'oidc',
      intent: 'get',
      provider,
      fingerprint,
      identity: credential,
    };

    const resp: Response = await fetch(
      this.session.buildUrl('/sessions/oauth'),
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          ...headers,
        },
        body: JSON.stringify(requestBody),
      }
    );

    const data = await resp.json();

    if (!resp.ok) {
      throw new ShowbieServiceError(data.errors);
    }

    return {
      ...data.session,
      region,
      fingerprint,
    };
  }
}
