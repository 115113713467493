export const PREFERRED_PAGE_LENGTH_LS_KEY = 'page-length-preference';
export const LAST_VIEWED_PURCHASER_LS_KEY = 'last-viewed-purchaser';
export const PREVIOUS_AUTH_ERROR_KEY = 'previous-auth-error';

/**
 * SERVICE ERROR CODES
 */
// Purchaser has no active subscription
export const ERROR_NO_SUBSCRIPTION_PAYWALL = '54020';
// Purchaser subscription does not grant access to Admin Analytics feature
export const ERROR_ANALYTICS_PAYWALL = '54021';
// Purchaser subscription has no paid seats
export const ERROR_NO_PRO_SEATS_PAYWALL = '19019';
