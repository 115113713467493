import { tracked } from 'tracked-built-ins';

export interface IFilterableOption {
  name: string;
  value: string;
}

export interface ListFilterConfig {
  options: IFilterableOption[];
  label?: string;
  type?: 'string' | 'boolean';
  defaultValue?: string;
}

export default class ListFilter {
  label = '';
  type: 'string' | 'boolean';

  currentValues = tracked(Set);

  #defaultValue: string;

  defaultValues: string[];

  #options: IFilterableOption[];

  constructor({
    label,
    options,
    type = 'string',
    defaultValue = '',
  }: ListFilterConfig) {
    if (label) this.label = label;

    this.type = type;
    this.#options = options;
    this.#defaultValue = defaultValue.split(',').sort().join(',');
  }

  /**
   * A list of filter options including a `checked` property based on the
   * `defaultValue` of this instance. This is meant to be bound to checkbox
   * elements in the UI, but the checked state only applies on first render.
   */
  get options() {
    return this.#options.map((option) => {
      return {
        ...option,
        checked: this.currentValues.has(option.value),
      };
    });
  }

  get items() {
    return Array.from(this.currentValues).filter(Boolean).sort();
  }

  get count() {
    return this.items.length;
  }

  get matchesDefault() {
    const defaultValues = this.#defaultValue.split(',').filter(Boolean).sort();
    return this.items.toString() === defaultValues.toString();
  }

  get allSelected() {
    return this.items.length === this.#options.length;
  }

  get noneSelected() {
    return this.items.length === 0;
  }

  get validValues() {
    return this.#options.map((x) => x.value);
  }

  add(value: string) {
    if (this.validValues.includes(value)) {
      this.currentValues.add(value);
    }
  }

  remove(value: string) {
    if (this.validValues.includes(value)) {
      this.currentValues.delete(value);
    }
  }

  clear() {
    this.currentValues.clear();
  }

  selectAll() {
    this.#options.forEach((x) => this.add(x.value));
  }

  setValue(value: string) {
    value
      .split(',')
      .filter(Boolean)
      .forEach((x: string) => this.add(x));
  }

  toQP() {
    return this.items.join(',');
  }

  toString() {
    const isDefault = this.matchesDefault ? ' (default)' : '';
    return `[ListFilter] ${this.label.toLowerCase()}: ${this.items.join(
      ', '
    )}${isDefault}`;
  }
}
