/* istanbul ignore file */
import BaseAuthenticator from 'ember-simple-auth/authenticators/base';
import ENV from 'dashboard/config/environment';
import { inject as service } from '@ember/service';
import HttpAnalyticsService, {
  type ShowbieFetchHeaders,
} from 'dashboard/services/http-analytics';
import type { BlingModeSessionData, ShowbieRegionCode } from 'dashboard/types';
import type SessionService from 'dashboard/services/session';
import {
  type RegionResolutionData,
  resolveRegion,
} from '@showbie/authentication';
import { payloadFromJWT } from '@showbie/utilities';
import { isEmpty } from '@ember/utils';

const { auth } = ENV.apiConfig.services;

export default class BlingAuthenticator extends BaseAuthenticator {
  @service declare httpAnalytics: HttpAnalyticsService;
  @service declare session: SessionService;

  async authenticate(token: string): Promise<BlingModeSessionData> {
    if (!token) return Promise.reject();

    const payload = payloadFromJWT(token) as { rg: ShowbieRegionCode };
    if (!payload.rg)
      throw new Error('BlingMode JWT does not contain region code');

    const headers = this.httpAnalytics.headers;
    const regionData: RegionResolutionData = {
      contextType: 'regionCode',
      context: payload.rg,
    };
    const opts = { headers: { ...headers } as ShowbieFetchHeaders };

    // resolve and store the regional origin
    const region = await resolveRegion(regionData, opts, auth.origin);
    if (!region) throw new Error('BlingMode region could not be resolved');

    this.session.setApiRegion(region);

    return { token, region };
  }

  async restore(data: BlingModeSessionData): Promise<BlingModeSessionData> {
    return new Promise((resolve, reject) => {
      if (!isEmpty(data) && !isEmpty(data.token)) {
        if (data.region && data.region.origin) {
          this.session.setApiRegion(data.region);
        }
        return resolve(data);
      }
      return reject('Could not restore session - "token" missing.');
    });
  }

  async invalidate(): Promise<unknown> {
    return Promise.resolve();
  }
}
