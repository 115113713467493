const doc = {
  "kind": "Document",
  "definitions": [
    {
      "kind": "EnumTypeDefinition",
      "name": {
        "kind": "Name",
        "value": "TeacherSpecificAction"
      },
      "directives": [],
      "values": [
        {
          "kind": "EnumValueDefinition",
          "name": {
            "kind": "Name",
            "value": "DOWNGRADE_TEACHER_LICENSE"
          },
          "directives": []
        },
        {
          "kind": "EnumValueDefinition",
          "name": {
            "kind": "Name",
            "value": "REMOVE_TEACHER_FROM_SCHOOL"
          },
          "directives": []
        },
        {
          "kind": "EnumValueDefinition",
          "name": {
            "kind": "Name",
            "value": "RESEND_EMAIL_VERIFICATION"
          },
          "directives": []
        },
        {
          "kind": "EnumValueDefinition",
          "name": {
            "kind": "Name",
            "value": "RESET_TEACHER_PASSWORD"
          },
          "directives": []
        },
        {
          "kind": "EnumValueDefinition",
          "name": {
            "kind": "Name",
            "value": "UPGRADE_TEACHER_LICENSE"
          },
          "directives": []
        }
      ]
    }
  ],
  "loc": {
    "start": 0,
    "end": 167
  }
};
export default doc;
