/**
 * Encode a string using SHA-256 encryption.
 * Assumes window.crypto.subtle is supported.
 *
 * @param {String} input
 * @returns {String} SHA-256 encoded value of `input`
 * @see https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/digest#basic_example
 * @see https://blog.xaymar.com/2020/12/08/fastest-uint8array-to-hex-string-conversion-in-javascript
 */
export default async function sha256(input: string): Promise<string> {
  // encode as (utf-8) Uint8Array
  const msgUint8 = new TextEncoder().encode(input);
  // hash the message
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8);
  // convert buffer to byte array
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  // convert bytes to hex string
  const hashHex = hashArray
    .map((b) => b.toString(16).padStart(2, '0'))
    .join('');
  return hashHex;
}
