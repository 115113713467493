import ENV from 'dashboard/config/environment';
const {
  APP: { pagination },
} = ENV;
import window from 'ember-window-mock';

import { PREFERRED_PAGE_LENGTH_LS_KEY } from 'dashboard/constants';

export default function preferredPageLength() {
  const lsValue = window.localStorage.getItem(PREFERRED_PAGE_LENGTH_LS_KEY);
  return lsValue ?? pagination.defaultPageLength;
}
