import Service from '@ember/service';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import CurrentUserService from 'dashboard/services/current-user';
import AnalyticsService from 'dashboard/services/analytics';
import type { AnalyticsEvent, AnalyticsEventName } from 'dashboard/types';

export enum TypeformModalTabType {
  FEEDBACK = 'FEEDBACK',
  IMPORT_TEACHERS = 'IMPORT_TEACHERS',
  IMPORT_CLASSES = 'IMPORT_CLASSES',
  LICENSE = 'LICENSE',
  STUDENTS = 'STUDENTS',
  TOOLS = 'TOOLS',
  UPGRADE = 'UPGRADE', // analytics section
  ADMINS = 'ADMINS',
  AUDITOR = 'AUDITOR',
  MIS_REQUEST_INTEGRATION = 'MIS_REQUEST_INTEGRATION',
  MIS_MODIFY_INTEGRATION = 'MIS_MODIFY_INTEGRATION',
}

export type TypeformModalTabTypeKey = keyof typeof TypeformModalTabType;

/**
 * Manager for all of our embedded Typeform forms.
 * See the `<TypeformDialog>` component, which is powered by this service.
 *
 * @see https://www.typeform.com/developers/embed/
 * @see https://www.typeform.com/developers/embed/custom-embed/
 */
export default class TypeformManagerService extends Service {
  @service declare currentUser: CurrentUserService;
  @service declare analytics: AnalyticsService;

  @tracked isOpen = false;
  @tracked tab: TypeformModalTabType;

  /** The current user */
  get user() {
    return this.currentUser.user;
  }

  /** The currently selected purchaser */
  get school() {
    return this.currentUser.currentPurchaser;
  }

  /**
   * Default values for "hidden fields" attached to all embedded Typeforms
   * @see https://www.typeform.com/help/a/using-hidden-fields-with-embedded-typeforms-360029264632/
   */
  get defaultTypeformURLParams() {
    const params = [
      ['accountname', this.school?.name],
      ['accountid', this.school?.id],
      ['fname', this.user?.firstName],
      ['lname', this.user?.lastName],
      ['email', this.user?.email],
    ];

    return params
      .map((field) => `${field[0]}=${encodeURIComponent(field[1] || '')}`)
      .join('&');
  }

  /**
   * Open a specific Typeform and, if needed,
   * fire the appropriate analytics event.
   */
  @action
  open(tab: TypeformModalTabType, analyticsEvent?: AnalyticsEvent) {
    this.tab = tab;
    this.isOpen = true;

    let eventName: AnalyticsEventName | undefined;
    switch (tab) {
      case TypeformModalTabType.TOOLS:
        eventName = 'app__admin_tools';
        break;
      case TypeformModalTabType.IMPORT_TEACHERS:
        eventName = 'app__import_teachers';
        break;
      case TypeformModalTabType.IMPORT_CLASSES:
        eventName = 'app__import_classes';
        break;
      case TypeformModalTabType.STUDENTS:
        eventName = 'app__import_students';
        break;
      case TypeformModalTabType.LICENSE:
        eventName = 'app__buy_license';
        break;
    }

    if (eventName) {
      const options = analyticsEvent?.options ?? {};
      this.analytics.track(eventName, options);
    }
  }

  /** Close the currently open Typeform modal */
  @action
  close(event: MouseEvent | null = null) {
    event?.stopPropagation();
    this.isOpen = false;
  }
}
