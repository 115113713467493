import type {
  RegionResolutionContextType,
  RegionResolutionData,
} from '../index';
import sha256 from './sha256';

/**
 * Format the body of the request made to the Showbie Auth service.
 *
 * If the contextType is 'user', the context is lowercased.
 * If the contextType is 'user' or 'qr', the context is sha256 encrypted.
 *
 * @param {RegionResolutionData} data
 * @returns {Promise<RegionResolutionResponse>}
 */
export async function formatFetchBody(
  data: RegionResolutionData
): Promise<RegionResolutionData> {
  let context = normalizeContext(data);
  if (isEncryptedContext(data.contextType)) {
    context = await sha256(context);
  }

  return {
    ...data,
    context,
  };
}

function normalizeContext(data: RegionResolutionData) {
  const { contextType, context } = data;
  return ['domain', 'user'].includes(contextType)
    ? context.toLowerCase()
    : context;
}

function isEncryptedContext(contextType: RegionResolutionContextType) {
  return contextType == 'user' || contextType == 'qr';
}
