import type { JSONAPIError } from 'dashboard/types';
import {
  ERROR_NO_PRO_SEATS_PAYWALL,
  ERROR_NO_SUBSCRIPTION_PAYWALL,
} from 'dashboard/constants';

export class ShowbieServiceError extends Error {
  private errors: JSONAPIError[];
  status: string;
  code: string;

  constructor(errors: JSONAPIError[], message = 'Showbie operation failed') {
    super(errors[0]?.title || message);
    const [firstError] = errors;
    this.errors = errors;
    this.status = firstError?.status || 'unknown';
    this.code = firstError?.code || 'unknown';
  }

  toString() {
    const [error] = this.errors;
    return error?.title ?? 'Unknown ShowbieServiceError';
  }
}

export class NoAccessError extends Error {
  constructor(
    message = 'Oops, it looks like you do not have access to the Admin Dashboard'
  ) {
    super(message);
    this.name = 'NoAccessError';
  }
}

/**
 * Detects if the given error code is related to school subcriptions or pro seats.
 */
export function isPaywallErrorCode(code = '') {
  return [ERROR_NO_PRO_SEATS_PAYWALL, ERROR_NO_SUBSCRIPTION_PAYWALL].includes(
    code
  );
}
