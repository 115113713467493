import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import type { IntlService } from 'ember-intl';
import type AnalyticsService from 'dashboard/services/analytics';
import type { FeatureService } from 'ember-feature-flags/services/features';
import type SessionService from 'dashboard/services/session';
import type GoogleClientService from 'dashboard/services/google-client';
import type RouterService from '@ember/routing/router-service';
import * as Msal from '@azure/msal-browser';
import {
  InteractionCancelled,
  OIDCClient,
  OIDCClientError,
} from '@plusauth/oidc-client-js';
import { task } from 'ember-concurrency';
import window from 'ember-window-mock';
import ENV from 'dashboard/config/environment';
import { SSOProvider } from 'dashboard/types';
import type {
  AnalyticsMetadata,
  SSOType,
  UserLoginCredentials,
  UserOAuthCredentials,
} from 'dashboard/types';
import { ShowbieServiceError } from 'dashboard/utils/errors';

const {
  APP: {
    authentication: { feide, microsoft },
    version,
  },
} = ENV;
export default class LoginController extends Controller {
  @service declare session: SessionService;
  @service declare googleClient: GoogleClientService;
  @service declare features: FeatureService;
  @service declare router: RouterService;
  @service declare analytics: AnalyticsService;
  @service declare intl: IntlService;

  @tracked errorMessage: string;
  @tracked submitting = false;

  userId: string;
  password: string;

  constructor(...args: []) {
    super(...args);
    this.googleClient.renderButton('google-signin-button');
  }

  signinWithMicrosoft = task(
    { drop: true },
    async (): Promise<void | Error> => {
      // see https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/login-user.md#redirecturi-considerations
      const redirectUri = microsoft.redirectUri;

      const msalConfig: Msal.Configuration = {
        auth: {
          authority: 'https://login.microsoftonline.com/common',
          clientId: microsoft.clientId,
          redirectUri,
        },
        telemetry: {
          application: {
            appName: 'Showbie Admin Dashboard',
            appVersion: version,
          },
        },
      };

      const msalInstance = new Msal.PublicClientApplication(msalConfig);

      const loginRequest: Msal.PopupRequest = {
        scopes: ['user.read', 'openid', 'profile'],
      };

      let authResult: Msal.AuthenticationResult;
      try {
        authResult = await msalInstance.loginPopup(loginRequest);
      } catch (err) {
        if (
          err instanceof Msal.ClientAuthError ||
          err instanceof Msal.BrowserAuthError
        ) {
          // General client errors (popup closed, multiple login sessions, etc.)
          console.error(err);
        } else {
          this.displayError(err);
        }
        return;
      }

      const options: UserOAuthCredentials = {
        accessToken: authResult.accessToken,
        idToken: authResult.idToken,
        provider: SSOProvider.MICROSOFT,
      };

      try {
        await this.session.authenticate('authenticator:microsoft', options);
        this.track('Microsoft');
      } catch (error) {
        this.displayError(error);
      }
    }
  );

  signinWithFeide = task({ drop: true }, async (): Promise<void | Error> => {
    const { authority, clientId: client_id, redirectPath } = feide;
    const { origin } = window.location;

    // https://docs.plusauth.com/tools/oidc-client-js
    const oidcClient = new OIDCClient({
      issuer: authority,
      client_id,
      redirect_uri: `${origin}/${redirectPath}`,
      scope: 'openid',
    });

    let idToken, accessToken;
    try {
      await oidcClient.initialize();
      await oidcClient.loginWithPopup();
      idToken = await oidcClient.getIdTokenRaw();
      accessToken = await oidcClient.getAccessToken();
    } catch (error) {
      if (error instanceof InteractionCancelled) {
        this.displayError('Feide popup window closed by user');
      } else if (
        error instanceof OIDCClientError &&
        error.message === 'Timed out'
      ) {
        this.displayError('Feide authentication timed out');
      } else {
        this.displayError(error);
      }
      return;
    }

    if (!accessToken || !idToken) return;

    const options: UserOAuthCredentials = {
      accessToken,
      idToken,
      provider: SSOProvider.FEIDE,
    };

    try {
      await this.session.authenticate('authenticator:feide', options);
      this.track('Feide');
    } catch (error) {
      this.displayError(error);
    }
  });

  @action async authenticate(e: Event): Promise<void> {
    e.preventDefault();
    this.submitting = true;
    const { userId, password } = this;
    try {
      if (!userId || !password) {
        throw `${this.intl.t('Error:')} ${this.intl.t(
          'All fields are required'
        )}`;
      }

      const options: UserLoginCredentials = {
        userId,
        password,
      };

      this.submitting = false;
      await this.session.authenticate('authenticator:showbie', options);
      this.track();
    } catch (error) {
      this.displayError(error);
      this.submitting = false;
    }
  }

  @action focusSkip(): void {
    const forwardElement = document.getElementById('forgot-password');
    forwardElement?.focus();
  }

  @action focusSkipOut(e: KeyboardEvent): void {
    e.preventDefault();
    const keyCode = e.keyCode ? e.keyCode : e.which;
    if (keyCode === 9) {
      if (e.shiftKey) {
        const backElement: HTMLElement | null = document.querySelector(
          'button[type="submit"]'
        );
        backElement?.focus();
      } else {
        const forwardElement = document
          .getElementById('google-signin-button')
          ?.querySelector('iframe');
        forwardElement?.focus();
      }
    }
  }

  displayError(error: ShowbieServiceError | Error | string) {
    this.errorMessage = error?.toString() ?? (error || 'Unknown error');
  }

  track(ssoType?: SSOType) {
    const opts: AnalyticsMetadata = {
      signInType: ssoType ? 'sso' : 'standard',
    };
    if (ssoType) {
      opts.ssoType = ssoType;
    }
    this.analytics.track('login__sign_in', opts).catch((err) => {
      console.debug(err.toString());
    });
  }
}
