import 'focus-visible';

import Application from '@ember/application';
import loadInitializers from 'ember-load-initializers';
import Resolver from 'ember-resolver';
import * as Sentry from '@sentry/ember';
import config from 'dashboard/config/environment';

// import Shoelace web components
import './shoelace';

if (config.environment === 'production') {
  /**
   * Sentry recommends all static SDK configuration be done here, in app.js,
   * and only using config/environment.js when needed.
   * @see https://github.com/getsentry/sentry-javascript/tree/master/packages/ember#usage
   */
  Sentry.init({
    dsn: 'https://d2bd0b83b25e44c3b3a1d11e8342cd25@o78392.ingest.sentry.io/5702713',
    environment: config.environment,
    // https://docs.sentry.io/platforms/javascript/configuration/releases/#bind-the-version
    release: 'com.showbie.admin@' + config.APP.version,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production,
    tracesSampleRate: 1.0,
  });
}

export default class App extends Application {
  modulePrefix = config.modulePrefix;
  podModulePrefix = config.podModulePrefix;
  Resolver = Resolver;
}

loadInitializers(App, config.modulePrefix);

function setDocHeight() {
  document.documentElement.style.setProperty('--vh', `${window.innerHeight}px`);
}

window.addEventListener('resize', setDocHeight);
window.addEventListener('orientationchange', setDocHeight);

setDocHeight();
