"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getIntercomRef = exports.ref = exports.init = void 0;
const init = () => __awaiter(void 0, void 0, void 0, function* () {
    var w = window;
    var ic = w.Intercom;
    if (w.intercomSettings) {
        w.intercomSettings.installation_type = "npm-package";
    }
    if (typeof ic === "function") {
        ic("reattach_activator");
        ic("update", w.intercomSettings);
    }
    else {
        var d = document;
        var i = function () {
            i.c(arguments);
        };
        i.q = [];
        i.c = function (args) {
            i.q.push(args);
        };
        w.Intercom = i;
        var l = function () {
            var _a, _b;
            var s = d.createElement("script");
            s.type = "text/javascript";
            s.async = true;
            s.src =
                "https://widget.intercom.io/widget/" + ((_a = window.intercomSettings) === null || _a === void 0 ? void 0 : _a.app_id);
            var x = d.getElementsByTagName("script")[0];
            (_b = x.parentNode) === null || _b === void 0 ? void 0 : _b.insertBefore(s, x);
        };
        if (document.readyState === "complete") {
            l();
        }
        else if (w.attachEvent) {
            w.attachEvent("onload", l);
        }
        else {
            w.addEventListener("load", l, false);
        }
    }
});
exports.init = init;
exports.ref = undefined;
const getIntercomRef = () => {
    if (typeof window !== "undefined" && !exports.ref) {
        exports.ref = window.Intercom;
    }
    if (!exports.ref || typeof window === "undefined") {
        console.warn("Intercom not booted or setup incomplete.");
        if (typeof window === "undefined") {
            console.warn("Are you sure you are running on client-side?");
        }
        return () => { };
    }
    return exports.ref;
};
exports.getIntercomRef = getIntercomRef;
