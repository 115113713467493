import {
  SlAvatar
} from "./chunk.YOEQI544.js";

// src/components/avatar/avatar.ts
var avatar_default = SlAvatar;
SlAvatar.define("sl-avatar");

export {
  avatar_default
};
