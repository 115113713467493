export type SortOptions = {
  sortBy: string;
  sortDir: string;
};

export interface SortOptionsWithDefaults extends SortOptions {
  defaultSortDir?: SortOptionsDirection;
}

export type SortOptionsDirection = 'asc' | 'desc';

/**
 * Cycle through sorting states for a table column.
 * Given the requested `field` to sort by, along with the current sorting
 * state of the table (`SortOptionsWithDefaults`), return a new sorting
 * state, with "sortBy" and "sortDir" values (`SortOptions`).
 */
export default function cycleSortOptions(
  field: string,
  { sortBy, sortDir, defaultSortDir = 'asc' }: SortOptionsWithDefaults
): SortOptions {
  const newSortOptions: SortOptions = { sortBy, sortDir };

  // always set new sortBy field
  newSortOptions.sortBy = field;

  if (sortBy === field) {
    // cycle between SortOptionsDirection
    newSortOptions.sortDir = sortDir === 'asc' ? 'desc' : 'asc';
  } else {
    // if changing columns/fields, set direction to default SortOptionsDirection
    newSortOptions.sortDir = defaultSortDir;
  }

  return newSortOptions;
}
