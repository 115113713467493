import type { CourseMember } from 'dashboard/types';
import { AccountStatus, EmailStatus } from 'dashboard/types';

export function getInitials(firstName: string, lastName = ''): string {
  return [
    firstName.charAt(0).toLocaleUpperCase(),
    lastName.charAt(0).toLocaleUpperCase(),
  ]
    .filter(Boolean)
    .join('');
}

export const isTeacher = function (user?: CourseMember) {
  return user?.__typename === 'Teacher';
};

export const isStudent = function (user?: CourseMember) {
  return user?.__typename === 'Student';
};

export const isBasic = function (user?: CourseMember): boolean {
  return !!user && isTeacher(user) && 'pro' in user && !user.pro;
};

export const isPro = function (user?: CourseMember): boolean {
  return !!user && isTeacher(user) && 'pro' in user && !!user.pro;
};

export const isSoloPro = function (user?: CourseMember): boolean {
  return (
    !!user && isTeacher(user) && 'pro' in user && isBasic(user) && user.soloPro
  );
};

export const isEmailConfirmed = function (user?: CourseMember): boolean {
  return !!user?.email && user.emailStatus === EmailStatus.VERIFIED;
};

export const isEmailUnconfirmed = function (user?: CourseMember): boolean {
  return !!user?.email && user.emailStatus !== EmailStatus.VERIFIED;
};

export const isMember = function (user?: CourseMember): boolean {
  return user?.accountStatus === AccountStatus.MEMBER;
};

export const isPending = function (user?: CourseMember): boolean {
  return user?.accountStatus === AccountStatus.PENDING;
};

export const isSynced = function (user?: CourseMember): boolean {
  return user?.accountStatus === AccountStatus.SYNCED;
};

export const isDeactivated = function (user?: CourseMember): boolean {
  return user?.accountStatus === AccountStatus.DEACTIVATED;
};
