import { v4 } from 'uuid';

import type { RegionResolutionData, RegionResolutionResponse } from '../index';
import { formatFetchBody } from './format-payload';
import { fetchRequest } from './request';

/**
 * Call the global Showbie Auth service to resolve the region for a given piece
 * of data. You can customize the request origin and headers with the `origin`
 * and `options.headers` arguments, respectively.
 *
 * @param {RegionResolutionData} data The data used to resolve the region. This will be converted into the request body.
 * @param {object} options Fetch request options. Currently, only the `headers` key is considered.
 * @param {Headers} options.headers Custom headers to add on the region resolution request
 * @param {string} origin The origin to use to make the region resolution request (e.g. https://my.showbie.com)
 * @returns {Promise<RegionResolutionResponse>}
 * @see https://github.com/showbie/showbieauthenticationservice/tree/QA#region
 */
export async function resolveRegion(
  data: RegionResolutionData,
  options: RequestInit = {},
  origin = ''
): Promise<RegionResolutionResponse> {
  let headers = options.headers || {};
  headers = {
    ...headers,
    'Content-Type': 'application/json',
    'x-showbie-correlation-id': v4(),
  };

  const url = `${origin}/auth/v1/region`;
  const body = await formatFetchBody(data);

  return fetchRequest(url, {
    method: 'POST',
    headers,
    body: JSON.stringify(body),
  }).then((response) => {
    if (response.ok) return response.json();

    return response.json().then((json) => {
      throw json;
    });
  });
}
