import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';
import platform from 'platform';
import ENV from 'dashboard/config/environment';

export interface HttpAnalyticsHeaders {
  'x-showbie-clientapikey': string;
  'x-showbie-accept-language': string;
  'x-showbie-appversion': string;
  'x-showbie-device': string;
  'x-showbie-devicetimezone': number;
  'x-showbie-os': string;
  'x-showbie-scale': number;
  'x-showbie-display-mode'?: string;
}

export type ShowbieFetchHeaders = HeadersInit & HttpAnalyticsHeaders;

const version = ENV.APP.version?.split('+')?.[0] ?? 'unknown'; // 0.0.0+99f44f8e

export default class HttpAnalyticsService extends Service {
  @tracked version = version;
  @tracked scale = window.devicePixelRatio || 1;
  @tracked timezone = -new Date().getTimezoneOffset() / 60;
  @tracked os = `${platform.os?.family} ${platform.os?.version}`.trim();
  @tracked language = 'en';

  get device(): string {
    const product = platform.product || platform.description;
    const manufacturer = platform.manufacturer || '';
    return `${product} ${manufacturer}`.trim();
  }

  get displayMode(): string | null {
    // @see https://developer.mozilla.org/en-US/docs/Web/API/Navigator
    // @ts-expect-error "standalone" is a non-standard (Apple only) property
    if (navigator.standalone) {
      return 'standalone-ios';
    }
    if (window.matchMedia('(display-mode: fullscreen)').matches) {
      return 'fullscreen';
    }
    if (window.matchMedia('(display-mode: standalone)').matches) {
      return 'standalone';
    }
    if (window.matchMedia('(display-mode: minimal-ui)').matches) {
      return 'minimal-ui';
    }

    return null;
  }

  get headers(): HttpAnalyticsHeaders {
    const headers: HttpAnalyticsHeaders = {
      'x-showbie-clientapikey': ENV.APP.authentication.showbie.clientApiKey,
      'x-showbie-accept-language': this.language,
      'x-showbie-appversion': this.version,
      'x-showbie-device': this.device,
      'x-showbie-devicetimezone': this.timezone,
      'x-showbie-os': this.os,
      'x-showbie-scale': this.scale,
    };

    // Keeping this the same as showbie-auth. Might want to confirm that it's necessary.
    if (this.displayMode) {
      headers['x-showbie-display-mode'] = this.displayMode;
    }

    return headers;
  }
}
