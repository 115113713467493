import {
  SlIconButton
} from "./chunk.WQ4LAAP4.js";

// src/components/icon-button/icon-button.ts
var icon_button_default = SlIconButton;
SlIconButton.define("sl-icon-button");

export {
  icon_button_default
};
