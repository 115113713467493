import type { ApolloQueryResult } from '@apollo/client/core';
import { QueryManager } from '@apollo/client/core/QueryManager';
import Service from '@ember/service';
import { inject as service } from '@ember/service';
import type Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type SessionService from 'dashboard/services/session';
import query from 'dashboard/gql/queries/user.gql';
import type { School, User } from 'dashboard/types';
import sortPurchasers from 'dashboard/utils/sort-purchasers';
import window from 'ember-window-mock';
import { LAST_VIEWED_PURCHASER_LS_KEY } from 'dashboard/constants';
import { NoAccessError } from 'dashboard/utils/errors';

export default class CurrentUserService extends Service {
  @service apollo: QueryManager<Component>;
  @service session: SessionService;
  @tracked user: User;
  @tracked selectedPurchaserId: string;

  /**
   * Fetch the currently authenticated user's data and store it in the
   * `user` field. This method should be called from the top level of the
   * route hierarchy (after login) to ensure that all child routes can assume
   * the the current user model data has been loaded successfully.
   *
   * If there is any error while retrieve user data this method will throw an
   * error and the session should be invalidated (at the calling site).
   */
  async load(): Promise<ApolloQueryResult<User> | void> {
    if (!this.session.isAuthenticated) {
      console.debug('Cannot load unauthenticated user');
      throw new Error('Cannot load unauthenticated user');
    }

    let result: ApolloQueryResult<User>;
    try {
      result = await this.apollo.query({ query });
      // @ts-ignore
      this.user = result.user;
    } catch (error) {
      // specifically catch 401 errors
      if (error?.graphQLErrors?.[0]?.extensions?.status === 401) {
        throw new NoAccessError('User is not an administrator');
      }
      // but any other error should trigger invalidation as well
      throw new NoAccessError();
    }

    // @ts-ignore
    if (result?.user?.purchasers?.length < 1) {
      /**
       * Abort if there are no purchasers returned.
       * The entire application depends on a list of administered purchasers and
       * none are returned it implies that the user is not an administrator.
       */
      throw new NoAccessError();
    }

    // set a default purchaser ID so we have a relevent context
    const purchaserId = this.determineInitialPurchaserId(this.user.purchasers);
    this.updateSelectedPurchaser(purchaserId);

    return result;
  }

  get currentPurchaser(): School | undefined {
    if (!this.selectedPurchaserId) return;
    return this.user.purchasers.find((p) => p.id === this.selectedPurchaserId);
  }

  updateSelectedPurchaser(id: string) {
    if (this.isValidPurchaserId(id)) {
      this.selectedPurchaserId = id;
      window.localStorage.setItem(LAST_VIEWED_PURCHASER_LS_KEY, String(id));
    }
  }

  /**
   * Check localStorage for a cached purchaser ID and validate it.
   * If invalid or not found, fallback to the first purchaser in the list.
   */
  determineInitialPurchaserId(purchasers: School[]) {
    const cachedId = window.localStorage.getItem(LAST_VIEWED_PURCHASER_LS_KEY);
    const isCachedIdValid = cachedId && this.isValidPurchaserId(cachedId);
    const purchaserId = isCachedIdValid
      ? cachedId
      : sortPurchasers(purchasers)[0].id;
    return purchaserId;
  }

  /**
   * Confirm that a given purchaser ID exists in the current user's list
   * of administered purchasers.
   */
  isValidPurchaserId(id: string) {
    return this.user?.purchasers?.map((x: School) => x.id).includes(id);
  }
}
